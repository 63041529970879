@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: DM Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #262F3D;
}
