.app-background {
  background: linear-gradient(
    225.01deg,
    #a3ef28 0%,
    rgba(74, 149, 249, 0.5) 37.92%,
    rgba(74, 149, 249, 0.1) 59.9%,
    rgba(74, 149, 249, 0) 70.89%
  );
}

.how-it-works {
  background: rgba(255, 255, 255, 0.05);
}

.how-it-works-index {
  color: #262f3d;
}

.upload-file {
  color: #262f3d;
}

.drop-zone {
  background: rgba(38, 47, 61, 0.05);
  border: 1px dashed rgba(38, 47, 61, 0.25);
}

.button-choose-csv {
  box-sizing: border-box;
  padding: 8px 16px;
  background: #4a95f9 !important;
  border: 1px solid rgba(38, 47, 61, 0.1);
  box-shadow: 0px 4px 8px rgba(38, 47, 61, 0.25);
}

.button-choose-csv:hover {
  background: #0c65db !important;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
}

.drop-here {
  color: rgba(38, 47, 61, 0.5);
}

.on-drag {
  background: #4a95f9;
}

.privacy-policy {
  color: #4a95f9;
}

.error {
  color: #f45b5b;
  border-color: #f45b5b !important;
}

.try-again {
  color: rgba(38, 47, 61, 0.75);
}

.file-view {
  background: rgba(38, 47, 61, 0.05);
}

.input-sku {
  border-color: rgba(38, 47, 61, 0.25) !important;
}

.upload-new {
  color: #4a95f9;
  padding: 8px 16px;
  background: #ffffff !important;
  border: 1px solid rgba(38, 47, 61, 0.1);
  box-shadow: 0px 4px 8px rgba(38, 47, 61, 0.1);
}

.upload-new:hover {
  background: #dadada !important;
}

.upload-bar {
  background: #262f3d;
}

.modal-text {
  color: rgba(38, 47, 61, 0.75);
}

.modal-image-container {
  width: 57rem;
} 

.nav-icon {
  background: #93989f;
}

.nav-icon:hover {
  background: #4A95F9;
  cursor: pointer;
}

.small-screen-navigator-background {
  background: linear-gradient(90deg, rgba(38, 47, 61, 0.5) 0%, rgba(38, 47, 61, 0) 100%);
}

.cancel-file {
  top: -5px;
  right: -5px;
  background: white;
}

.cancel-file:hover {
  background: #4A95F9;
}

@media screen and (min-height: 781px) {
  .app {
    height: 100vh;
  }
}

@media screen and (max-height: 780px) {
  .app {
    height: '100%';
  }
}